import { PointData, TextStyleOptions } from 'pixi.js';

export type UUID = ReturnType<typeof crypto.randomUUID>;

export enum AppEvent {
	INIT = 'init',
	UPDATE_TEXT_FIELD = 'update-text-field',
	UPDATE_IMAGE_FIELD = 'update-image-field',
}

export type InitEventData = ImportedStage;

export type UpdateEventData = {
	id: UUID;
	value: string;
};

export type ImportedStage = {
	background: string;
	width: number;
	height: number;
	images: ImportedImage[];
	textFields: ImportedTextField[];
	imageFields: ImportedImageField[];
};

export type ImportedImage = {
	id: UUID;
	name: string;
	size: number;
	pos: PointData;
	src: string;
	width: number;
	height: number;
	zIndex: number;
};

export type ImportedTextField = {
	id: UUID;
	pos: PointData;
	name: string;
	width: number;
	maxLinesQuantity: number;
	value: string;
	style: Partial<TextStyleOptions>;
};

export type ImportedImageField = {
	id: UUID;
	name: string;
	pos: PointData;
	width: number;
	height: number;
};
