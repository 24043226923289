import { Application } from 'pixi.js';
import { ImportedStage } from './types';

async function init(root: HTMLDivElement, config: ImportedStage) {
	console.log(root);

	const app = new Application();

	await app.init({
		background: '#ff0000',
		width: 600,
		height: 650,
		resizeTo: root,
		// background: config.background,
		// width: config.width,
		// height: config.height,
	});
	console.log(app);

	root.appendChild(app.canvas);

	return app;
}

export default init;
