import { Core } from './Core.ts';
import { AppEvent, InitEventData, UpdateEventData } from './types.ts';
import { Application, Renderer } from 'pixi.js';
import init from './init.ts';
import testConfig from './testConfig.ts';
import src1 from './assets/197843.jpg';
import src2 from './assets/5845303_orig.jpg';
import src3 from './assets/dragonball-el-post-que-vegeta-se-merece-megapost-taringa.jpg';

const root = document.querySelector<HTMLDivElement>('#silveo_view')!;
console.log(root);

let initializedApp: Application<Renderer>, core: Core;

root.addEventListener(AppEvent.INIT, (event) => {
	console.log(initializedApp);

	// if (initializedApp) {
	// 	initializedApp.destroy(
	// 		{
	// 			removeView: true,
	// 		},
	// 		{ children: true, context: true, texture: true, textureSource: true }
	// 	);
	// 	root.removeEventListener(
	// 		AppEvent.UPDATE_TEXT_FIELD,
	// 		updateTextFieldListener
	// 	);
	// 	root.removeEventListener(
	// 		AppEvent.UPDATE_IMAGE_FIELD,
	// 		updateImageFieldListener
	// 	);
	// }
	const appEvent = event as CustomEvent<InitEventData>;
	const config = appEvent.detail;

	init(root, config).then((app) => {
		// initializedApp = app;
		// core = new Core(app, config);
		// root.addEventListener(AppEvent.UPDATE_TEXT_FIELD, updateTextFieldListener);
		// root.addEventListener(
		// 	AppEvent.UPDATE_IMAGE_FIELD,
		// 	updateImageFieldListener
		// );
	});
});

function updateTextFieldListener(event: Event) {
	const appEvent = event as CustomEvent<UpdateEventData>;

	const { id, value } = appEvent.detail;
	core.setTextFieldValue(id, value);
}

function updateImageFieldListener(event: Event) {
	const appEvent = event as CustomEvent<UpdateEventData>;

	const { id, value } = appEvent.detail;
	core.setImageFieldTexture(id, value);
}

if (import.meta.env.DEV) {
	setTimeout(() => {
		const ev = new CustomEvent<InitEventData>(AppEvent.INIT, {
			detail: testConfig,
		});

		root.dispatchEvent(ev);
	}, 2000);

	setTimeout(() => {
		for (const imgFconfig of testConfig.imageFields) {
			const ev = new CustomEvent<UpdateEventData>(AppEvent.UPDATE_IMAGE_FIELD, {
				detail: {
					id: imgFconfig.id,
					value: src2,
				},
			});

			root.dispatchEvent(ev);
		}
	}, 3000);

	setTimeout(() => {
		for (const imgFconfig of testConfig.imageFields) {
			const ev = new CustomEvent<UpdateEventData>(AppEvent.UPDATE_IMAGE_FIELD, {
				detail: {
					id: imgFconfig.id,
					value: src2,
				},
			});

			root.dispatchEvent(ev);
		}
	}, 6000);
}
